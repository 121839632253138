import events from 'static/js/app/base/events';
import helpers from "static/js/app/helpers/helpers";

export default function () {
  app.storage.clear();

  let campaignId = helpers.getURLParameter("c");
  if (campaignId === null || campaignId === undefined){
    events.fire.navigate('index.html');  
  } else {
    events.fire.navigate('/api/candidate/authentication/sso-login/unlocked?campaignId=' + campaignId);
  }
};
