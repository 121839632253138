import consentsdefault from 'static/js/app/pagescripts/private/consents-default';
import accountactivated from 'static/js/app/pagescripts/private/account-activated';
import forgotpass from 'static/js/app/pagescripts/private/forgot-pass';
import gametypes from 'static/js/app/pagescripts/private/game-types';
import home from 'static/js/app/pagescripts/private/home';
import index from 'static/js/app/pagescripts/private/index';
import introductionstep from 'static/js/app/pagescripts/private/introduction-step';
import passconfirm from 'static/js/app/pagescripts/private/pass-confirm';
import passreset from 'static/js/app/pagescripts/private/pass-reset';
import privacy from 'static/js/app/pagescripts/private/privacy';
import profilepage from 'static/js/app/pagescripts/private/profile-page';
import objectGames from 'static/js/app/pagescripts/private/games-init';
import objectResultScreens from 'static/js/app/pagescripts/private/result-screens';
import remoteWork from 'static/js/app/pagescripts/private/result-screens-remote-work';
import consents from 'static/js/app/pagescripts/private/consents';
import sso from 'static/js/app/pagescripts/private/sso';
import unlocked from 'static/js/app/pagescripts/private/unlocked';

class SingletonPageScriptService {
    constructor() {
        if (!SingletonPageScriptService.instance) {
            SingletonPageScriptService.instance = this;
            this.pagescripts = {
                consentsdefault,
                accountactivated,
                forgotpass,
                gametypes,
                home,
                index,
                introductionstep,
                passconfirm,
                passreset,
                privacy,
                profilepage,
                ...objectGames,
                ...objectResultScreens,
                'resultscreensremotework': remoteWork,
                consents,
                sso,
                unlocked
            };

            // page names that match will require a logged-in user
            // others will be able to be seen with or without a logged in user. For these, please see pass-reset.html warning about translations
            this.forceLoginPages = [
                "game1", "game2", "game3", "game4", "game5", "game-types", "introduction-step", "profile-page", "agreement",
                "resultscreens1", "resultscreens2", "resultscreens-remote-work", "resultscreens1-one-topskill"
            ];
        }
        return SingletonPageScriptService.instance;
    }

    shouldGoHome(isLoggedIn, currentPage) {
        if (!isLoggedIn && this.forceLoginPages.indexOf(currentPage) > -1) {
            return true;
        }
        return false;
    }

    loadPageJs(currentPage) {
        // find page script by name convention, remove all "-" and convert index-* to index
        let pageScriptKey = currentPage.replace(/-/g, "");
        if (pageScriptKey.slice(0, 5).toLowerCase() == "index") {
            pageScriptKey = "index";
        }

        const result = this.pagescripts[pageScriptKey];

        if (!result) {
            console.warn(`PAGE SCRIPT SERVICE: no page script for: '${pageScriptKey}'`);
        }
        return result;
    }
};

const instance = new SingletonPageScriptService();
Object.freeze(instance);
export default instance;
